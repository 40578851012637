.home {
	width: 100vw;
	height: 100vh;
	padding: 0.75rem;
}

.location {
	margin-left: 0.75rem;
	font-size: 1rem;
}

.userSearch {
	margin-bottom: 1.5rem;
}

.userList {
	max-height: 15rem;
	overflow-y: auto;
}

.adm-cascader-header-button {
	font-size: var(--adm-font-size-10) !important;
}

.adm-center-popup {
	--min-width: 85vw;
	--max-width: 100vw;
}

.btnTop{
	margin-top: 4rem;
}
