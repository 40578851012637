.header {
	box-sizing: border-box;
	padding: 12px;
	background-color: #fff;
}
.content {
	padding: 12px;
}

.content .show {
	display: block;
}

.content .hiden {
	display: none;
}

.footer {
	display: flex;
}

.footer button {
	margin: 0 0.75rem;
}
