.questionCard {
	position: relative;
	padding: 0.85rem 1.5rem 1.5rem;
	overflow: hidden;
	background-color: #fff;
}

.header {
	display: flex;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.index {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3rem;
	height: 3rem;
	color: #fff;
	font-weight: 500;
	font-size: 1.125rem;
	background-color: var(--adm-color-primary);
	border-bottom-right-radius: 2.25rem;
}

.title {
	flex: 1;
	padding-left: 2.25rem;
	color: rgba(0, 0, 0, 0.85);
	font-size: 1.125rem;
}

.extra {
	flex: 0 0 4rem;
	width: 4rem;
	color: var(--adm-color-primary);
	font-size: 1rem;
	text-align: right;
}

.extra.checkbox {
	color: var(--adm-color-success);
}
