.App {
	width: 100%;
	height: 100%;
}
.adm-cascader-header-button {
	font-size: 1.125rem;
}

.adm-cascader-view-header-title {
	font-size: 1rem;
}

.adm-list-item-content-main {
	font-size: 1rem;
}

.adm-center-popup-wrap {
	min-width: 85vw !important;
	max-width: 100vw !important;
}
